<template>
  <div class="rank-gift">
    <el-button type="primary" class="gift-button" @click="openGiftCenter"
      >待领礼品>></el-button
    >

    <el-dialog
      :visible.sync="showGiftCenter"
      width="340px"
      custom-class="gift-center"
      center
      :show-close="false"
    >
      <div slot="title" class="title">奖品信息</div>
      <ul class="gift-content">
        <!-- <li class="gift-item add" @click="openAddressCenter('add')">
          +新增收件人信息
        </li> -->
        <li class="gift-item" v-for="item in giftList" :key="item.id">
          <div class="line-item first">
            <img :src="item.cover" alt="" />
            <div class="content">{{ item.commodityName }}</div>
          </div>
          <div
            class="line-item update"
            @click="openAddressCenter('update', item.id)"
          >
            修改收件人信息
          </div>
          <div class="line-item">
            <div class="desc">收 件 人:</div>
            <div class="ontent">{{ item.name }} {{ item.phone }}</div>
          </div>
          <div class="line-item">
            <div class="desc">收件地址:</div>
            <div class="content">{{ item.address }}</div>
          </div>
          <!-- <div class="line-item">
            <div class="desc">寄出状态:</div>
            <div class="content state">
              {{ item.handle ? '已发货' : '未发货' }}
            </div>
          </div>
          <div class="line-item">
            <el-button
              class="confirm-button"
              type="primary"
              @click="updateGiftState(true, item.id)"
              :disabled="!item.handle || item.isReceive"
              >已收货</el-button
            >
            <el-button
              class="confirm-button"
              type="primary"
              @click="updateGiftState(false, item.id)"
              :disabled="!item.handle || !item.isReceive"
              >未收货</el-button
            >
          </div> -->
        </li>
      </ul>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddressCenter"
      width="340px"
      custom-class="address-center"
      center
      :show-close="false"
    >
      <div slot="title" class="title">
        {{ type === 'add' ? '新增地址' : '修改地址' }}
      </div>

      <div class="address-content">
        <div class="name address-item">
          <div class="desc">收货人姓名</div>
          <el-input v-model="name" placeholder="请输入收货人姓名" />
        </div>
        <div class="phone address-item">
          <div class="desc">收货人电话</div>
          <el-input v-model="phone" placeholder="请输入收货人电话" />
        </div>
        <div class="address address-item">
          <div class="desc">所在区域</div>
          <div class="selects">
            <el-select
              size="mini"
              class="select"
              v-model="province"
              placeholder="请选择"
            >
              <el-option
                v-for="(val, key) in provinces"
                :key="key"
                :label="key"
                :value="key"
              >
              </el-option>
            </el-select>
            <el-select
              size="mini"
              class="select"
              v-model="city"
              placeholder="请选择"
            >
              <el-option
                v-for="(val, key) in citys"
                :key="key"
                :label="key"
                :value="key"
              >
              </el-option>
            </el-select>
            <el-select
              size="mini"
              class="select"
              v-model="area"
              placeholder="请选择"
            >
              <el-option
                v-for="item in areas"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="detailed address-item">
          <div class="desc">详细地址</div>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="detailedAddress"
          >
          </el-input>
        </div>
        <div class="remarks address-item">
          <div class="desc">备注</div>
          <el-input type="textarea" placeholder="请输入内容" v-model="remarks">
          </el-input>
        </div>
        <div class="default address-item" v-if="type === 'add'">
          <div class="desc">设为默认地址</div>
          <el-radio-group v-model="defaultAddress">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div class="button address-item">
          <el-button class="address-button" @click="addressInfoHandler"
            >确定</el-button
          >
          <el-button class="address-button" @click="showAddressCenter = false"
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAddressList,
  addAddressInfo,
  getGiftList,
  updateGiftInfo,
} from '@/api/rank';

import { chinaAddress } from '@/utils/address';

export default {
  data() {
    return {
      showGiftCenter: false,
      showAddressCenter: false,
      giftList: [],
      type: '',
      id: '',
      name: '',
      phone: '',
      province: '',
      city: '',
      area: '',
      detailedAddress: '',
      remarks: '',
      defaultAddress: '',
      initPage: 1,
    };
  },
  computed: {
    provinces() {
      return chinaAddress;
    },
    citys() {
      this.city = '';
      if (this.province) {
        return this.provinces[this.province];
      } else {
        return {};
      }
    },
    areas() {
      this.area = '';
      if (this.city) {
        return this.citys[this.city];
      } else {
        return [];
      }
    },
  },
  methods: {
    openGiftCenter() {
      (async () => {
        try {
          const result = await getGiftList({ pageNo: this.initPage });
          if (result.success) {
            this.giftList.push(...result.result.records);
            this.showGiftCenter = true;
            if (this.initPage < result.result.pages) {
              this.initPage++;
              this.openGiftCenter();
            }
          }
        } catch (error) {
          console.log(error);
        }
      })();
    },
    openAddressCenter(type, id) {
      this.type = type;
      this.id = id;
      this.showAddressCenter = true;
    },
    addressInfoHandler() {
      if (this.name === '') {
        this.$alert('姓名不能为空');
        return;
      }
      if (
        !/^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(
          this.phone,
        )
      ) {
        this.$alert('请输入正确的电话号码');
        return;
      }
      if (this.city === '') {
        this.$alert('请选择您所在的城市');
        return;
      }
      if (this.area === '') {
        this.$alert('请选择您所在的区县');
        return;
      }
      if (this.detailedAddress === '') {
        this.$alert('请输入您的详细地址');
        return;
      }
      if (this.defaultAddress === '' && this.type === 'add') {
        this.$alert('请选择是否设为默认地址');
        return;
      }

      if (this.id) {
        (async () => {
          try {
            const result = await updateGiftInfo({
              name: this.name,
              phone: this.phone,
              address:
                this.province + this.city + this.area + this.detailedAddress,
              desc: this.remarks,
              id: this.id,
            });

            if (result.success) {
              this.$message('修改成功');
              this.openGiftCenter();
              this.showAddressCenter = false;
            } else {
              this.$message(`修改失败,${result.message}`);
            }
          } catch (error) {
            console.log(error);
          }
        })();
      } else {
        (async () => {
          try {
            const result = await addAddressInfo({
              name: this.name,
              phone: this.phone,
              city: this.city,
              area: this.area,
              detailedAddress: this.detailedAddress,
              defaultAddress: this.defaultAddress,
            });

            if (result.success) {
              this.$message('添加成功');
              this.showAddressCenter = false;
            } else {
              this.$message(`添加失败,${result.message}`);
            }
          } catch (error) {
            console.log(error);
          }
        })();
      }
    },
    updateGiftState(isReceive, id) {
      (async () => {
        try {
          const result = await updateGiftInfo({
            isReceive,
            id,
          });
          console.log(1);
          if (result.success) {
            this.$message('修改成功');
            this.giftList = [];
            this.initPage = 1;
            this.openGiftCenter();
          } else {
            this.$message(`修改失败,${result.message}`);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    },
  },
};
</script>

<style lang="scss" scoped>
.gift-button {
  background-color: #5f80ff;
  border-color: #5f80ff;
  width: 130px;
  height: 34px;
  font-size: 12px;
  &:hover {
    background-color: #8aa0fa;
    border-color: #8aa0fa;
  }
}
.confirm-button {
  background-color: #5f80ff;
  border-color: #5f80ff;
  width: 128px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  &:hover {
    background-color: #8aa0fa;
    border-color: #8aa0fa;
  }
}
.confirm-button.is-disabled {
  background-color: #666;
  border-color: #666;
}
.address-content {
  .address-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
    .desc {
      width: 100px;
      padding-right: 14px;
      font-size: 14px;
      flex: none;
      text-align: right;
    }
    .selects {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .select {
        width: 80px;
      }
    }
  }
  .address-item.button {
    justify-content: space-around;
  }
  .button {
    justify-content: space-between;
    margin-top: 20px;
    .address-button {
      color: #fff;
      width: 144px;
      height: 40px;
      background-color: #5f80ff;
      border-color: #5f80ff;
      font-size: 14px;
      &:hover {
        background-color: #8aa0fa;
        border-color: #8aa0fa;
      }
    }
  }
}

.gift-content {
  width: 100%;
  // .gift-item.add {
  //   color: #5f80ff;
  //   cursor: pointer;
  //   text-align: center;
  //   width: 100%;
  //   margin: 0;
  //   font-size: 16px;
  // }
  .gift-item {
    font-size: 14px;
    .line-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      line-height: 24px;
      font-weight: 400px;
      color: #000;
      img {
        width: 58px;
        height: 58px;
        margin-right: 20px;
        flex: none;
      }
      .content {
        flex: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        /*！autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /*！autoprefixer: on */
        color: #000;
      }
      .desc {
        width: 70px;
        flex: none;
      }
      .state {
        color: #5f80ff;
      }
    }
    .line-item.first {
      line-height: 32px;
    }
    .line-item:first-child {
      align-items: center;
    }
    .line-item:last-child {
      justify-content: space-around;
      .gift-button {
        background-color: #5f80ff;
        border-color: #5f80ff;
        width: 113px;
        height: 35px;
        font-size: 12px;
        color: #fff;
        &:hover {
          background-color: #8aa0fa;
          border-color: #8aa0fa;
        }
      }
    }
    .update {
      color: #5f80ff;
      cursor: pointer;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #ddd;
      margin: 20px 0 20px;
    }
    &:last-child:after {
      content: '';
      display: none;
    }
  }
}
</style>

<style lang="scss">
.address-center,
.gift-center {
  width: 464px !important;
  height: 600px !important;
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__body {
    padding: 15px;
    overflow-y: auto;
    height: 550px;
  }
  .el-dialog__header {
    padding: 0;
    width: 100%;
    height: 50px;
    // background-color: #5f80ff;
    background-image: url(~@/assets/image/title-banner.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
  }
}
.address-center {
  height: auto !important;
  width: 400px !important;
  .el-dialog__body {
    padding: 15px;
    overflow-y: inherit;
    height: auto;
  }
}

.select {
  .el-input__inner {
    padding: 0 5px;
  }
}
</style>